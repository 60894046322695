.w-full.flex.items-center.bg-white.py-2.px-6, .pl-1.pt-1 {
}
.hidden {
}
.p-6 {
    padding: 0!important;
}
.captionflow #scrubber-progress {
    max-width: var(--progress);
}
.captionflow {
    font-family: 'Helvetica';
}
