

.vjs-poster {
  background-color: #fff;
}

.vjs-tech {
  background-color: #fff;
}

.vjs-fullscreen .vjs-tech {
  background-color: #000;
}
